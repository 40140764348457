var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-dialog',{model:{value:(_vm.$store.state.modalBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "modalBusqueda", $$v)},expression:"$store.state.modalBusqueda"}},[_c('div',{staticClass:"col-12 row justify-center",staticStyle:{"width":"1200px"}},[_c('q-card',{staticClass:"bg-card-buscador",staticStyle:{"width":"1200px","opacity":"0.9","font-family":"'Titillium Web', sans-serif"}},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"highlight_off","flat":"","round":"","dense":""},on:{"click":function($event){return _vm.cerrar()}}})],1),_c('q-card-section',{staticClass:"scroll q-pt-none",staticStyle:{"max-height":"60vh"}},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-12 row justify-center text-center"},[_c('label',{staticStyle:{"font-size":"20px"}},[_vm._v("Encuentra todo lo relacionado con el Municipio")])]),_c('div',{staticClass:"col-11 row justify-center text-center"},[_c('q-input',{staticClass:"full-width",attrs:{"type":"search","placeholder":"¿Qué necesitas encontrar?","dark":"","color":"white","rounded":"","outlined":"","clearable":"","dense":"","item-aligned":"","maxlength":"80"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"search","color":"white"},on:{"click":function($event){return _vm.buscar(_vm.$store.state.textBusqueda)}}})]},proxy:true}]),model:{value:(_vm.$store.state.textBusqueda),callback:function ($$v) {_vm.$set(_vm.$store.state, "textBusqueda", $$v)},expression:"$store.state.textBusqueda"}})],1)])]),_c('q-card-section',{staticClass:"scroll q-pt-none",staticStyle:{"max-height":"60vh"}},[_c('q-card-section',{staticClass:"q-pt-none"},[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[(
                  _vm.busquedaData &&
                    _vm.busquedaData.dataPaginas &&
                    _vm.busquedaData.dataPaginas.length == 0 &&
                    _vm.busquedaData.dataGalerias &&
                    _vm.busquedaData.dataGalerias.length == 0 &&
                    _vm.busquedaData.dataNoticias &&
                    _vm.busquedaData.dataNoticias.length == 0 &&
                    _vm.busquedaData.dataDocumentos &&
                    _vm.busquedaData.dataDocumentos.length == 0 &&
                    _vm.links &&
                    _vm.links.length == 0
                )?_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",staticStyle:{"font-size":"28px"}},[_vm._v(" No se han encontrado resultados ")])]):_vm._e()])]),(
              _vm.busquedaData &&
                _vm.busquedaData.dataNoticias &&
                _vm.busquedaData.dataNoticias.length > 0
            )?_c('q-card-section',{staticClass:"q-pt-none"},[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Noticias ")])]),_vm._l((_vm.busquedaData.dataNoticias),function(item,index){return _c('div',{key:item.id,staticClass:"row col-12 q-mt-md"},[(index > 0)?_c('q-separator',{staticClass:"q-mb-md",staticStyle:{"z-index":"2"},attrs:{"dark":"","size":"2px"}}):_vm._e(),_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.irNoticia(item.id, item.nombre_web)}}},[_vm._v(" "+_vm._s(item.titulo)+" ")]),_c('div',{staticClass:"col-12 q-mt-md q-mb-md cursor-pointer",style:('font-size: ' + _vm.fontTexto + 'px; color: white !important'),on:{"click":function($event){return _vm.irNoticia(item.id, item.nombre_web)}}},[_c('div',{domProps:{"innerHTML":_vm._f("truncate")(item.descripcion_corta,120, '...')}})])],1)})],2)]):_vm._e(),(
              _vm.busquedaData &&
                _vm.links &&
                _vm.links.length > 0 &&
                _vm.busquedaData.dataNoticias &&
                _vm.busquedaData.dataNoticias.length > 0
            )?_c('q-separator',{attrs:{"color":"white","inset":""}}):_vm._e(),(_vm.busquedaData && _vm.links && _vm.links.length > 0)?_c('q-card-section',[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Links de Interés ")])]),_vm._l((_vm.links),function(item,index){return _c('div',{key:item.id,staticClass:"row col-12 q-mt-md"},[(index > 0)?_c('q-separator',{staticClass:"q-mb-md",staticStyle:{"z-index":"2"},attrs:{"dark":"","size":"2px"}}):_vm._e(),(item.tipo == 1)?_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.irPagina(item.nombre_web)}}},[_vm._v(" "+_vm._s(item.nombre)+" ")]):_vm._e(),(item.tipo == 0)?_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.toLink(item.url)}}},[_vm._v(" "+_vm._s(item.nombre)+" ")]):_vm._e()],1)})],2)]):_vm._e(),(
              _vm.busquedaData &&
                _vm.links &&
                _vm.links.length > 0 &&
                _vm.busquedaData.dataDocumentos &&
                _vm.busquedaData.dataDocumentos.length > 0
            )?_c('q-separator',{attrs:{"color":"white","inset":""}}):_vm._e(),(
              _vm.busquedaData &&
                _vm.busquedaData.dataDocumentos &&
                _vm.busquedaData.dataDocumentos.length > 0
            )?_c('q-card-section',[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Documentos ")])]),_vm._l((_vm.busquedaData.dataDocumentos),function(item,index){return _c('div',{key:item.id,staticClass:"row col-12 q-mt-md"},[(index > 0)?_c('q-separator',{staticClass:"q-mb-md",staticStyle:{"z-index":"2"},attrs:{"dark":"","size":"2px"}}):_vm._e(),_c('div',{staticClass:"col-12 text-bold cursor-pointer",on:{"click":function($event){return _vm.descargarDocumento(item.archivo)}}},[_vm._v(" "+_vm._s(item.nombre)+" ")])],1)})],2)]):_vm._e(),(
              _vm.busquedaData &&
                _vm.busquedaData.dataGalerias &&
                _vm.busquedaData.dataGalerias.length > 0
            )?_c('q-card-section',[_c('div',{staticClass:"row text-busqueda",style:('font-size: ' + _vm.fontExterno + 'px;')},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-white",style:('font-size: ' + _vm.fontTitulo + 'px;')},[_vm._v(" Galerías ")])]),_vm._l((_vm.busquedaData.dataGalerias),function(item){return _c('div',{key:item.id,staticClass:"\n                  row\n                  col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3\n                  bg-img\n                  q-ma-sm\n                  cursor-pointer\n                  justify-center\n                ",on:{"click":function($event){return _vm.irGaleria(item.nombre_web)}}},[_c('div',{staticClass:"col-12 q-pa-md cursor-pointer",on:{"click":function($event){return _vm.irGaleria(item.nombre_web)}}},[_c('q-img',{staticStyle:{"width":"100% !important","height":"auto !important","border-style":"!important"},attrs:{"src":item.imagen_previa}})],1),_c('div',{staticClass:"\n                    col-11\n                    row\n                    justify-center\n                    q-my-md\n                    text-center\n                    cursor-pointer\n                  ",staticStyle:{"font-size":"18px","font-family":"'Titillium Web', sans-serif","font-weight":"700"},on:{"click":function($event){return _vm.irGaleria(item.nombre_web)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.nombre,48, "..."))+" ")])])})],2)]):_vm._e()],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }